/**
 * Here you can add the configuration related to keycloak
 * So we can use this common config for diffrent environment
 */
import { KeycloakConfig } from 'keycloak-js';

// Test
const keycloakConfig: KeycloakConfig = {
  url: 'https://keycloak-test.pandoradevelop.it',
  realm: 'pandora',
  clientId: 'app',
};


export default keycloakConfig;
